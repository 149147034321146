import React from "react";
import {
  SEO,
  PageLayout,
  HoursOfOperation,
  Image,
  Location,
  Map,
  InternalLink,
  Content,
  ImageCollection,
  Contact,
} from "@bluefin/components";
import { Embed, Grid, Segment, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage, getPageGalleryImages } from "../utils/utils";
import "../style/index.css";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout hero={false} subfooter={false}>
          <div>
            <div className={"yt-container"}>
              <Embed
                url={
                  "https://player.vimeo.com/video/1060964169?badge=0&autopause=0&player_id=0&app_id=58479"
                }
                allow={"autoplay; fullscreen; picture-in-picture"}
                defaultActive={true}
                autoPlay={true}
                title={"bunmeup_slap"}
                className={"home-hero-video"}
              />
              <div className={"hero-cta"} />
            </div>
          </div>
          <Grid
            stackable={true}
            id={"orderOnline"}
            className={"component-section-container"}
            textAlign={"center"}
          >
            <Grid.Row>
              <h1>HOURS OF OPERATION</h1>
              <HoursOfOperation
                displayOption={"dailyWithPopup"}
                displayOpenClosedStatus={true}
                enhancedLabels={{
                  openingSoon: "Opening soon",
                  open: "Open now",
                }}
                timezone={fishermanBusiness.primaryLocation.timezone}
                hours={fishermanBusiness.primaryLocation.hours}
              />
            </Grid.Row>
            <Grid.Row stretched={true}>
              <Grid.Column width={8} textAlign={"center"}>
                <Segment padded={"very"} raised={true}>
                  <h1>ORDER FOR PICKUP</h1>
                  <div>
                    <a href={"https://bunmeup.square.site/"} target={"_blank"}>
                      <Image
                        size={"medium"}
                        centered={true}
                        className={"squareLogo"}
                        rounded={true}
                        src={
                          "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/Square_Logotype_black-055fee9861574695abd75aa53554c448.png"
                        }
                      />
                    </a>
                  </div>
                  <Location
                    businessName={""}
                    phone={null}
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                  />
                  <Map
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column width={8} textAlign={"center"}>
                <Segment padded={"very"} raised={true}>
                  <h1>ORDER FOR DELIVERY</h1>
                  <div>
                    <Button
                      primary={true}
                      circular={true}
                      size={"massive"}
                      className={"massive-catering-cta"}
                      to={"/catering/"}
                      as={InternalLink}
                    >
                      CATERING
                    </Button>
                  </div>
                  <div>
                    <a
                      href={"https://order.online/business/bun-me-up-12983596"}
                      target={"_blank"}
                    >
                      <Image
                        size={"medium"}
                        className={"doordashLogo"}
                        centered={true}
                        rounded={true}
                        src={
                          "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/DoorDash-logo_y2MUIbJ-removebg-preview.png"
                        }
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={
                        "https://www.order.store/store/bun-me-up/-r4k8fWzXduqYU8OFLiN7Q"
                      }
                      target={"_blank"}
                    >
                      <Image
                        size={"medium"}
                        className={"doordashLogo"}
                        centered={true}
                        rounded={true}
                        src={
                          "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/Uber_Eats_Logo_621Megc-removebg-preview.png"
                        }
                      />
                    </a>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Content>
                <Content.Markup>
                  {fishermanBusiness.aboutMarkdown}
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={getPageGalleryImages({
                  options: fishermanBusinessWebsitePage.components,
                })}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable={true} className={"component-section-container"}>
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                email={fishermanBusiness.primaryLocation.email}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
              <div className={"ui container"}>
                <div className={"ui stackable grid"}>
                  <div
                    className={"eleven wide column custom contact-graphic"}
                  />
                  <div
                    className={
                      "middle aligned four wide column contact-content"
                    }
                  >
                    <header className={"ui header"}>Catering</header>
                    <div className={"item"}>
                      <div className={"content"}>
                        <header className={"header h5 day-label"}>
                          Monday-Sunday
                        </header>
                        <div>8:00am - 11:00pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        timezone
        hours {
          day
          open
          close
          label
        }
        street
        city
        state
        zipCode
        phoneNumber
        email
      }
      aboutMarkdown
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
